import React from "react";
import logo from "../images/SerbWordWhite.svg";
import { useNavigate } from "react-router-dom";
import { RiLogoutCircleLine } from "react-icons/ri";

export default function Nav() {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    return (
        <div className="flex min-w-full py-3 sticky top-0 z-20  bg-gradient-to-br from-[#12589e] to-serbBlue justify-between items-center sm:px-5 md:px-16">
            <div className="w-full">
                <img src={logo} alt="serbius logo"  className="md:w-52 sm:w-20"/>
            </div>

            <div className="w-full">
                <img src="https://pub-f61289f764c647a3ba8348e3b5855890.r2.dev/cbrtlogo.webp" alt="cancer and bowel trust logo"  className="md:w-52 sm:w-20 md:-ml-20"/>
            </div>
            
            <button onClick={logout} className="text-white text-2xl">
                <RiLogoutCircleLine />
            </button>
        </div>
    );
}
