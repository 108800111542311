import React, {useState} from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";

export default function PaymentForm({ setName, name, delayPayment, pledge, email, raffleType, delayedDate, setStripeId, message, setMessage, selectedLead, paid, setPaid }) {
    
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form behavior
    
        if (!stripe || !elements) {
            alert("Stripe is not properly initialized.");
            return;
        }

        if(paid){
            alert("already paid")
            return
        }
    
        try {
            // Define the endpoint and format delayed date if applicable
            const endpoint = delayPayment
                ? `${process.env.REACT_APP_BACKEND_URL}/create-setup-intent`
                : `${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`;
    
            const formattedDelayedDate = delayedDate ? new Date(delayedDate).toISOString() : null;
    
            // Prepare payload for the backend
            const payload = {
                pledge,
                raffleType,
                email: selectedLead.email,
                name,
                delayedDate: formattedDelayedDate,
            };
    
            console.log("Payload being sent:", payload); // Debug log
    
            // Send payload to the backend
            const { data } = await axios.post(endpoint, payload);
    
            // Save the Stripe customer ID
            setStripeId(data.customerId);
            setPaid(true)
    
            const cardElement = elements.getElement(CardElement);
    
            if (delayPayment) {
                // Handle setup intent for delayed payment
                const { error } = await stripe.confirmCardSetup(data.clientSecret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            email,
                            name,
                        },
                    },
                });
    
                if (error) {
                    console.error("Stripe setup error:", error);
                    setMessage(`Stripe Setup error`);

                } else {
                    alert("Payment details saved. You will be charged later.");
                    setMessage(`Delayed Payment Successful`);
                }
            } else {
                // Handle immediate payment
                const { error } = await stripe.confirmCardPayment(data.clientSecret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            email,
                            name,
                        },
                    },
                });
    
                if (error) {
                    console.error("Stripe payment error:", error);
                    setMessage("Failed to process payment.");
                } else {
                    alert("Payment successful!");
                    setMessage(`Payment successful! Customer ID: ${data.customerId}`);

                }
            }
        } catch (error) {
            console.error("Error processing payment:", error);
            alert("Failed to process payment.");
        }
    };
    

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-4 ">
            <input
                type="text"
                placeholder="Name on Card"
                className="p-2 border rounded"
                required
                onChange={(e) => setName(e.target.value)}
            />
            <CardElement
                options={{
                    style: {
                        base: {
                            fontSize: "16px",
                            color: "#424770",
                            "::placeholder": { color: "#aab7c4" },
                        },
                        invalid: { color: "#9e2146" },
                    },
                }}
                className="p-2 border rounded"
            />
             <button
                                className="bg-blue-500 self-end text-white p-3 rounded"
                                onClick={handleSubmit}
                            >
                                {delayPayment ? "Save for Later Payment" : "Pay Now"}
                            </button>

                            {message && <p className="text-red-500 mt-4">{message}</p>}
        </form>
    );
};
