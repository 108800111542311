import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { decodeToken } from 'react-jwt';
import axios from "axios";
import { RiArrowDropDownLine } from "react-icons/ri";
import Nav from "../components/Nav";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements, Elements } from "@stripe/react-stripe-js"; 
import { EmbeddedCheckoutProvider, EmbeddedCheckout} from '@stripe/react-stripe-js';
import PaymentForm from "../components/PaymentForm";



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Home(){
    const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true)
  const [leadsLoaded, setLeadsLoaded] = useState(false)
  const [verified, setVerfied] = useState(true)
  const [raffleType, setRaffleType] = useState(2);
  const [pledge, setPledge] = useState('')
  const [saveSale, setSaveSale] = useState(false)
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [selectedLeadId, setSelectedLeadId] = useState('');
  const [selectedLead, setSelectedLead] = useState(null);
  const [outcome, setOutcome] = useState('');
  const [email, setEmail] = useState('')
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [accountName, setAccountName] = useState('');
  const [agentusername, setAgentusername] = useState('')
  const [bsb, setbsb] = useState('')
  const [accountNumber, setAccountNumber] = useState()
  const [ticket, setTicket] = useState(0);
  const [orderNumber, setOrderNumber] = useState('')
  const [projectName, setProjectName] = useState("")
  const [donation, setDonation] = useState('')
  const [agentSent, setAgentSent] = useState(false);
  const [isNewSupplier, setIsNewSupplier] = useState(false);
  const [showLeads, setShowLeads] = useState(true);
  const [modalUrl, setModalUrl] = useState('');
  const [charities, setCharities] = useState([])
  const [raffles, setRaffles] = useState([]);
  const [verify, setVerify] = useState(false);
  const [raffleName, setRaffleName] = useState('')
  const [submitDate, setSubmitDate] = useState('');
  const [delayedDate, setDelayedDate] = useState('')
  const [stripeId, setStripeId] = useState('')
  const [message, setMessage] = useState("");
  const [paid, setPaid] = useState(false)



  const [delayPayment, setDelayPayment] = useState(false);
    const [name, setName] = useState('');



  useEffect(() => {
    const token = localStorage.getItem('token');

    // Check token validity
    if (token) {
        const decodedToken = decodeToken(token);
        if (decodedToken) {
            // If token is valid, set user data and fetch leads
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                setUser(JSON.parse(storedUser));
            }
            fetchLeads();
        } else {
            // Token is invalid
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            navigate('/');
        }
    } else {
        // Redirect to login if no token
        navigate('/');
    }
}, [navigate]);

  

  const fetchLeads = async () => {
    try {
      const token = localStorage.getItem('token');
      const data = '';
  
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      const hmac = CryptoJS.HmacSHA256(data, secretKey);
      const hmacDigest = hmac.toString(CryptoJS.enc.Hex);
  
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/leads`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-HMAC-Signature': hmacDigest,
        },
        withCredentials: true,
      });
  
      setLeads(response.data);
      setLoading(false);
      setLeadsLoaded(true);
    } catch (error) {
      console.error('Error:', error.message || 'Failed to connect to server');
    }
  };
  
  
  const stripe = useStripe(); // Initialize stripe
  const elements = useElements();
  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = decodeToken(token);
      if (!decodedToken) {
        localStorage.removeItem('token');
        navigate('/'); // Redirect to login if token is invalid
      } else {
        fetchLeads(); // Only populate home if token is valid
      }
    } else {
      navigate('/'); // Redirect if no token is found
    }
  }, [navigate]);

  const handleStripe = async (cardElement, stripeInstance, e) => {
    e.preventDefault();
    try {
        // Define endpoint and format the delayed date if necessary
        const endpoint = delayPayment 
            ? `${process.env.REACT_APP_BACKEND_URL}/create-setup-intent`
            : `${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`;

        const formattedDelayedDate = delayedDate ? new Date(delayedDate).toISOString() : null;

        // Prepare payload for backend
        const payload = {
            pledge,
            raffleType,
            email: selectedLead.email,
            name,
            delayedDate: formattedDelayedDate,
        };

        console.log("Payload being sent:", payload); // Debug log

        // Send the payload to the backend
        const { data } = await axios.post(endpoint, payload);


        setStripeId(data.customerId);


        if (delayPayment) {
            // Setup Intent for delayed payment
            const { error } = await stripeInstance.confirmCardSetup(data.clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        email,
                        name,
                    },
                },
            });

            if (error) {
                console.error("Stripe setup error:", error);
                alert("Failed to set up delayed payment.");
            } else {
                alert("Payment details saved. You will be charged later.");
            }
        } else {
            // Immediate Payment Intent for "Pay Now"
            const { error } = await stripeInstance.confirmCardPayment(data.clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        email,
                        name,
                    },
                },
            });

            if (error) {
                console.error("Stripe payment error:", error);
                alert("Failed to process payment.");
            } else {
                alert("Payment successful!");
            }
        }
    } catch (error) {
        console.error("Error processing payment:", error);
        alert("Failed to process payment.");
    }
};




    const handleleadSelect = (leadId) => {
        setSelectedLeadId(leadId);
    
        // Find the lead with the selected ID
        const lead = leads.find(lead => lead._id === leadId);
        setShowLeads(false)
        setSelectedLead(lead);
      };

    const handleAddNewSupplier = () => {
        setSelectedLead({
          ...selectedLead,
          supplierCustomerId: searchTerm, // Ensure the search term becomes the supplierCustomerId
          firstName: '',
          lastName: '',
          email: '',
          address: '',
          suburb: '',
          state: '',
          postCode: '',
          phoneNumber: '',
          pledge: 0,
          ticket: 0,
          donation: 0,
          paymentMethod: 1,
          projectName: '',
          outcome: '',
          agentName: '',
          raffleName: '',
          raffleType: 2,
        });
      
        setIsNewSupplier(true);
        setShowLeads(false);
        alert("Successful, now input customer's information");
      };

      const handleSearchChange = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        setShowLeads(true)
  
    
        if (searchTerm === '') {
          setFilteredLeads([]);
        } else {
          const filtered = leads.filter(lead =>
            lead.supplierCustomerId.toLowerCase() === searchTerm.toLowerCase()
          );
          setFilteredLeads(filtered);
        }
      };

      const handleInputChange = (event) => {
        const { name, value } = event.target;
  
        setSelectedLead(prevState => ({
          ...prevState,
          [name]: value
        }));
  
        if (name === 'projectName') {
          setProjectName(value);
        }
  
        switch (name) {
          case 'outcome':
            setOutcome(value);
            break;
            case 'email':
            setEmail(value);
            break;
            case 'accountNumber':
              setAccountNumber(value);
          break;
          case 'accountName':
              setAccountName(value);
          break;
          
          case 'bsb':
              setbsb(value);
          break;
          case 'agentusername':
              setAgentusername(value);
          break;
            case 'pledge':
            setPledge(value);
            break;
            case 'donation':
            setDonation(value);
            break;
            case 'orderNumber':
            setOrderNumber(value);
            break;
            case 'submitDate':
            setSubmitDate(value);
            break;
            case 'ticket':
            setTicket(value);
            break;
            
            
      }
    
        setSelectedLead(prevState => ({
          ...prevState,
          [name]: name === 'paymentMethod' ||  name === 'raffleType' ? parseInt(value) : value
        }));
      };

      const sendSaveSale = async (e) => {
        e.preventDefault();
  
        if(raffleType === 2){
          alert("Please select the Payment Type")
          return
        }

  
      setSelectedLead({
        supplierCustomerId: searchTerm,
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        suburb: '',
        state: '',
        postCode: '',
        phoneNumber: '',
        pledge: 0,
        ticket: 0,
        donation: 0,
        paymentMethod: 1,
        projectName: '',
        submitDate: '',
        outcome: '',
        agentName: `${user.username}`,
        agentusername: `${user.username}`,
        raffleName: '',
        orderNumber: '',
        raffleType: 2,
        stripeCusId: stripeId,
      });
      
        if (selectedLead) {
          try {
            // Check if the supplierCustomerId already exists in the database
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sales/check-duplicate`, {
              params: { supplierCustomerId: selectedLead.supplierCustomerId },
              withCredentials: true,
            });
      
            if (response.data.exists) {
              alert('This supplierCustomerId already exists');
              return;
            }
  
            const todayDate = new Date().toISOString()
      
            const saleData = {
              supplierCustomerId: selectedLead.supplierCustomerId,
              firstName: selectedLead.firstName,
              lastName: selectedLead.lastName,
              address: selectedLead.address,
              suburb: selectedLead.suburb,
              state: selectedLead.state,
              postCode: selectedLead.postCode,
              email: selectedLead.email,
              projectName: "CBRT",
              phoneNumber: selectedLead.phoneNumber,
              donation: selectedLead.donation,
              ticket,
              bsb,
              accountName,
              accountNumber,
              agentName: `${user.username}`, // Ensure this is the correct user info
              supplierId: selectedLead.supplierId || "SERBIUS",
              outcome: message,
              paymentMethod: 0,
              raffleType,
              pledge,
              raffleName,
              agentusername: `${user.username}`,
              team: `${user.team}`,
              paid: false,
              stripeCusId: stripeId,
              submitDate: delayedDate || todayDate
            };
      
            const data = JSON.stringify(saleData);
      
            // Create HMAC using CryptoJS
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const hmac = CryptoJS.HmacSHA256(data, secretKey).toString(CryptoJS.enc.Hex);
      
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sales/newsale`, saleData, {
              headers: {
                'X-HMAC-Signature': hmac,
              },
              withCredentials: true,
            });
            setPaid(false)
            alert('Sale Saved');
            setIsNewSupplier(false)
            window.location.reload();
          } catch (err) {
            console.error(err);
            const errorMessage = err.response?.data?.message || 'An error occurred while submitting the sale information.';
            alert(errorMessage);
          }
        } else {
          alert('No lead selected or form is incomplete.');
        }
      };


    return(
        <div className="min-h-screen bg-[#eaeaea] relative">
            <Nav user={user}/>

            <div className="md:px-16 sm:px-5 min-w-full py-10 flex flex-col gap-5">

            <div className="container flex flex-col min-w-full justify-start">
                        <h3 className="text-lg sans p-5 px-5 bg-white w-full">Sale Information</h3>


                        <div className=" flex flex-col gap-3">
                        <div className="flex flex-col px-10 bg-white w-full justify-between items-center h-min py-5 gap-5 p-1">
                        
                        <div className="flex md:flex-row sm:flex-col w-full justify-between">
                            <input
                                className='border-solid h-12 text-center  text-black placeholder:text-black bg-gray-100 p-1 md:w-1/4 sm:w-full rounded-lg'
                                id="leadSearch"
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search Customer"
                            />
                            {showLeads && (
                                filteredLeads.length > 0 ? (
                                <ul className='bg-white h-12 border-purple-800 p-1 hover:bg-purple-200 border-2 rounded-lg mt-2'>
                                    {filteredLeads.map(lead => (
                                    <li
                                        key={lead._id}
                                        className='cursor-pointer p-2 hover:bg-cyan-200'
                                        onClick={() => handleleadSelect(lead._id)}
                                    >
                                        {lead.supplierCustomerId}
                                    </li>
                                    ))}
                                </ul>
                                ) : (
                                searchTerm && 
                                <div className="flex md:flex-row sm:flex-col gap-3">
                                    <div className='mt-2 '>
                                        <p className="text-red-500 font-medium">No ID found in records</p>
                                    </div>
                                    <button type="button" onClick={handleAddNewSupplier} className="bg-serbBlue p-2 text-white rounded-lg helv-med hover:bg-blue-400">
                                        Add Supplier Customer Id
                                    </button>
                                    </div>
                                )
                            )}
                        </div>
                        
                        <form onSubmit={sendSaveSale} className="container flex flex-col gap-5 py-5 min-w-full ">  

                            <div className='container grid lg:grid-cols-5 sm:grid-cols-1 w-full text-center justify-center gap-4'>
                                <div className='container lg:col-span-1 sm:col-span-1  flex w-full flex-col justify-center'>
                                    <select
                                    className='text-center py-2 bg-gray-100 helv' 
                                    name="title"
                                    value={selectedLead?.title || ''}
                                    onChange={handleInputChange}
                                    >
                                    <option value="">--Title--</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Dr">Dr</option>
                                    <option value="Prof.">Prof.</option>
                                    <option value="Mr">Mr</option>
                                    </select>
                                </div>
                                <div className='container lg:col-span-2 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                                    <input
                                    className='text-center py-2 bg-gray-100 helv'
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={selectedLead?.firstName || ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                <div className='container lg:col-span-2 sm:col-span-1  flex w-full flex-col justify-center gap-1'>
                                    <input
                                    className='text-center py-2 bg-gray-100 helv'
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={selectedLead?.lastName || ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='container grid lg:grid-cols-6 sm:grid-cols-1 lg:grid-rows-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
                                <div className='container flex lg:col-span-3 sm:col-span-1 w-full flex-col justify-center gap-1'>
                                    <input
                                    className='text-center py-2 bg-gray-100 helv'
                                    type="text"
                                    name="address"
                                    placeholder="Address"

                                    value={selectedLead?.address || ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                    
                                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                                    <input
                                    className='text-center py-2 bg-gray-100'
                                    type="text"
                                    name="suburb"
                                    placeholder="Suburb"
                                    value={selectedLead?.suburb || ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                    
                                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                                    <input
                                    className='text-center py-2 bg-gray-100'
                                    type="text"
                                    name="state"
                                    placeholder="State"
                                    value={selectedLead?.state || ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                    
                                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                                    <input
                                    className='text-center py-2 bg-gray-100'
                                    type="text"
                                    name="postCode"
                                    placeholder="Post Code"
                                    value={selectedLead?.postCode || ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='container grid lg:grid-cols-3 sm:grid-cols-1 lg:grid-rows-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
                                <div className='container flex lg:col-span-1 sm:col-span-1 w-full flex-col justify-center gap-1'>
                                    <input
                                    className='text-center py-2 bg-gray-100'
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    value={selectedLead?.email || ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                                    <input
                                    className='text-center py-2 bg-gray-100'
                                    type="text"
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    value={selectedLead?.phoneNumber || ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center'>
                                <select
                                className='text-center py-2 bg-gray-100'
                                name="raffleType"
                                value={raffleType}
                                onChange={(e) => setRaffleType(Number(e.target.value))}
                                >
                                <option value={2}>-- Payment Type --</option>

                                <option value={1}>Regular (4 Weekly)</option>
                                <option value={0}>One Time Payment</option>

                                </select>
                                </div>
                            </div>

                            <div className='container grid lg:grid-cols-3 sm:grid-cols-1 lg:grid-rows-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
                                <div className='container flex lg:col-span-1 sm:col-span-1 w-full flex-col justify-center gap-1'>
                                    <input
                                        className='text-center py-2 bg-gray-100'
                                        type="number"
                                        name="pledge"
                                        value={pledge}
                                        placeholder="Donation Amount"
                                        onChange={(e) => setPledge(Number(e.target.value))}
                                        required
                                    />
                                </div>
                                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                                    <input
                                    className='text-center py-2 bg-gray-100'
                                    type="text"
                                    name="outcome"
                                    placeholder="Outcome"
                                    value={message}
                                    onChange={handleInputChange}
                                    required
                                    />
                                </div>
                                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center'>
                                    <input
                                    className='text-center py-2 bg-gray-100'
                                    type="text"
                                    name="agentName"
                                    value={`${user.firstName} ${user.lastName}`}
                                    onChange={handleInputChange}
                                    />
                                </div>

                                
                            </div>

                            <div className="flex items-center mb-4">
                                <input
                                    type="checkbox"
                                    id="delayPayment"
                                    checked={delayPayment}
                                    onChange={(e) => setDelayPayment(e.target.checked)}
                                    className="mr-2"
                                />

                                <div className="flex gap-3">
                                <label htmlFor="delayPayment">Delay Payment</label>
                                {delayPayment && (
                                  <input type="date" value={delayedDate} onChange={(e) => setDelayedDate(e.target.value)}/>
                                )} 
                                </div>

                                

                                
                            </div>

                            

                              <Elements stripe={stripePromise}>
                              <PaymentForm
                                  delayPayment={delayPayment}
                                  delayedDate={delayedDate}
                                  pledge={pledge}
                                  raffleType={raffleType}
                                  email={email}
                                  name={name}
                                  setName={setName}
                                  handleStripe={handleStripe}
                                  setStripeId={setStripeId}
                                  message={message}
                                  setMessage={setMessage}
                                  selectedLead={selectedLead}
                                  paid={paid}
                                  setPaid={setPaid}
                              />
                          </Elements>

                            <button
                                className=' m-3 self-end p-2 w-max text-xl text-white bg-serbBlue hover:bg-cyan-600'
                                type="submit" 
                                >
                                Save Sale
                            </button>

                        </form>

                        

                        </div>
                        </div>
                    
                    
                </div>

      
                
            </div>

            <div className="bottom-0 absolute flex justify-between w-full p-3">
                <p>Copyright &copy; 2024 Aydmar Holdings Ltd. All rights Reserved.</p>
                <p>Version 1.0.1</p>

            </div>
        </div>
    )
}