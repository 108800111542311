import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  return (
    <BrowserRouter>
    <Elements stripe={stripePromise}>
      <Routes>
        <Route path='/home' Component={Home} />
        <Route path='/' Component={SignIn} />

      </Routes>
      </Elements>
    </BrowserRouter>
  );
}

export default App;
