import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from '../images/SerbWordWhite.svg'

export default function SignIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/users/signin`,
                { username, password },
                { withCredentials: true }
            );

            const data = response.data;

            if (data.status === 'ok' && data.user) {
                if (data.token) {
                    localStorage.setItem('token', data.token);
                } else {
                    console.warn('Token not found in response');
                }
                localStorage.setItem('user', JSON.stringify(data.user));
                navigate('/home');
            } else {
                alert(data.message || 'An error occurred');
            }
        } catch (err) {
            if (err.response) {
                setError(err.response.data.message || 'An error occurred');
                alert(err.response.data.message || error);
            } else if (err.request) {
                setError("No response from server. Please try again later.");
                alert(error);
            } else {
                setError("An error occurred. Please try again.");
                alert(error);
            }
        }
    };

    return (
        <div >
            <div className="header ">
                <div className="inner-header flex-box">
                    
                    <img src={logo} alt="serbius logo" className="h-12" />
                </div>

                <div>
                    <svg
                        className="waves"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none"
                        shapeRendering="auto"
                    >
                        <defs>
                            <path
                                id="gentle-wave"
                                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                            />
                        </defs>
                        <g className="parallax">
                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(21,188,200,0.7)" />
                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(21,188,200,0.5)" />
                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(21,188,200,0.3)" />
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#eaeaea" />
                        </g>
                    </svg>
                </div>
            </div>

            <div className=" container flex flex-col min-w-full h-screen absolute top-0  justify-center items-center">
                <div className=" h-max md:p-10 sm:p-5 md:w-1/2 sm:w-3/4 flex-box flex-col gap-5  bg-white shadow-lg">
                    <h1 className="font-bold text-2xl">Welcome Back,</h1>
                    <form onSubmit={handleSubmit} className="flex-box flex-col w-3/4 divide-y divide-serbBlue divide-opacity-30 divide-solid divide-x-1">
                        <input
                            placeholder="Username"
                            name="username"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full placeholder:text-center placeholder:uppercase p-4 text-center focus:outline-none"
                        />
                        <input
                            name="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            className="w-full placeholder:text-center placeholder:uppercase p-4 text-center focus:outline-none"
                        />

                        <button type="submit" className="text-center mt-3 px-3 py-3 shadow-md hover:bg-cyan-600 bg-serbBlue w-full text-white">Sign In</button>
                    </form>
                    
                    
                </div>

                <div className="bottom-0 absolute flex justify-between w-full p-3">
                <p>Copyright &copy; 2024 Aydmar Holdings Ltd. All rights Reserved.</p>
                <p>Version 1.0.1</p>

                </div>
                
            </div>
        </div>
    );
}
